<template>
<div class="mobile-menu-container">
    <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close" @click="hideMobileMenu">
            <i class="icon-close"></i>
        </span>
        <div class="cust-data" v-if="(customerSettingDetails && customerSettingDetails.secure_status !== 'A') || ( customerDetails && customerDetails.profile_status !== 'A') || $store.state.sessionStorage.login_successfull">
            <h3 class="summary-title-mob">{{customerMembershipDetails.group_name}}</h3>
            <p class="pre-des-mob">{{customerDetails.customer_first_name}}</p>
            <p v-if="customerDetails.customer_email" class="pre-des-mob">{{customerDetails.customer_email}}</p>
            <p v-if="customerDetails.customer_mobile" class="pre-des-mob">{{customerDetails.customer_mobile}}</p>
            <!-- <img class="qr-img" :src="walletQR ? walletQR : 'https://mzcoupon.s3.ap-southeast-1.amazonaws.com/coupon/qrcode/b69317aeed811d97e05888b5bc7da8fe_customer_id_qrcode.png'" alt="" style="margin-bottom:1em; height: 231px; width: 232px; object-fit: contain;"> -->
            <div v-if="!walletQrShow" >
                <img :src="customer_group_image" alt="" style="margin-bottom:1em; height: 232px; object-fit: contain; margin-top:1em;">
            </div>
            <div id="Qr-code" style="margin-top:1em" v-else>
                <img :src="walletQR" alt="" style="margin-bottom:1em; height: 232px; object-fit: contain; ">
            </div>
            <button v-if="!walletQrShow" id="mob-btn" type="submit" class="btn btn-outline-primary-2 btn-order btn-block" @click.prevent="ViewWalletQR()">
                <span class="btn-text">{{$t("components.mobilemenu.view_wallet_qr")}}</span>
                <span class="btn-hover-text">{{$t("components.mobilemenu.view_wallet_qr")}}</span>
            </button>
            <button v-else type="submit" class="btn btn-outline-primary-2 btn-order btn-block" id="mob-btn" @click.prevent="CloseWalletQR()">
                <span class="btn-text">{{$t("components.mobilemenu.close_wallet_qr")}}</span>
                <span class="btn-hover-text">{{$t("components.mobilemenu.close_wallet_qr")}}</span>
            </button>
            <button v-if="customerSettingDetails.secure_status === 'A' && !$store.state.sessionStorage.login_successfull" type="submit" class="btn btn-outline-primary-2 btn-order btn-block" id="mob-btn" @click.prevent="updateCustomer()">
                <span class="btn-text">{{$t('page.product_listing.secure_wallet')}}</span>
                <span class="btn-hover-text">{{$t('page.product_listing.secure_wallet')}}</span>
            </button>
        </div>
        <nav class="mobile-nav" >

            <ul class="mobile-menu">
                <div v-if="(customerSettingDetails && customerSettingDetails.secure_status !== 'A') || ( customerDetails && customerDetails.profile_status !== 'A') || $store.state.sessionStorage.login_successfull">
                <li >
                    <a href="#" class="sf-with-ul" @click="activeCoupon()">{{$t("components.mobilemenu.coupon_active")}}  <span class="item-count" >{{ activeCount}}</span></a>
                </li >
                <li>
                    <a href="#" class="sf-with-ul" @click="historyCoupon('T')">{{$t("components.mobilemenu.coupon_history")}} <span class="item-count" >{{ historycount}}</span></a>
                </li>
                <li>
                    <a href="#" class="sf-with-ul" @click="historyCoupon('E')">{{$t("components.mobilemenu.coupon_expired")}} <span class="item-count">{{ expirycount}}</span></a>
                </li>
                </div>
                <!-- <li >
                        <nuxt-link to="#" class="sf-with-ul">Transcation</nuxt-link>
                    </li> -->
                <li>
                    <nuxt-link to="#" class="sf-with-ul">{{$t("components.mobilemenu.language")}}</nuxt-link>

                    <ul>
                        <li v-for="(lang, index) of selectedLang" :key="index">
                            <a href="#" @click.prevent="lang_change(lang.code)">{{lang.name}}</a>
                        </li>

                    </ul>
                </li>

            </ul>
        </nav>
        <div class="social-icons">
            <a class="social-icon" :href="merchantDetails.facebook_url" target="_blank" title="Facebook" v-if="merchantDetails.facebook_url !== null && merchantDetails.facebook_url !== ''">
                <i class="icon-facebook-f"></i>
            </a>
            <a class="social-icon" :href="merchantDetails.twitter_url" target="_blank" title="Twitter" v-if="merchantDetails.twitter_url !== null && merchantDetails.twitter_url !== ''">
                <i class="icon-twitter"></i>
            </a>
            <a class="social-icon" :href="merchantDetails.instagram_url" target="_blank" title="Instagram" v-if="merchantDetails.instagram_url !== null && merchantDetails.instagram_url !== ''">
                <i class="icon-instagram"></i>
            </a>
            <a class="social-icon" :href="merchantDetails.youtube_url" target="_blank" title="Youtube" v-if="merchantDetails.youtube_url !== null && merchantDetails.youtube_url !== ''">
                <i class="icon-youtube"></i>
            </a>
        </div>
    </div>
</div>
</template>

<script>
import Tabs from '~/components/elements/Tabs';
import { mobileMenu } from '~/utilities/common.js';

export default {
    data: function () {
        return {
            searchTerm: '',
            MerchantSettings: '',
            selectedLang: '',
            merchantDetails: '',
            customerDetails: '',
            walletQR: '',
            walletQrShow: false,
            customerMembershipDetails: '',
            customerSettingDetails: '',
            customer_group_image: null,
            activeCount:'',
            historycount:'',
            expirycount:''
        };
    },
    mounted: function () {
        //this.customer_id = this.$store.state.sessionStorage.customer_id;
        this.customer_id = this.$route.params.id
        console.log("MobileMenu -customer_id##", this.customer_id)
        mobileMenu();
        this.merchantSettings();
        this.getMerchantDetails();
        this.GetCustomer();
        this.getCustomerSetting();
        this.getCoupons();
        this.getHistoryCoupons();
        this.getexpiryCoupons();
        // if (this.$store.state.sessionStorage.customerDetails) {
        //     this.customerDetails = this.$store.state.sessionStorage.customerDetails;
        //     console.log("customerDetails",this.customerDetails)
        //     this.walletQR = this.customerDetails.customer_image_url;
        // }

    },
    methods: {
        historyCoupon: function (status) {
            
            this.$store.commit('sessionStorage/assignListType', status);

        },

        activeCoupon: function (id) {
           
            this.$store.commit('sessionStorage/assignListType', 'A');
        },

        //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Coupon list api function >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
           getCoupons: async function () {
            var encrypt = this.$route.query.encrypted ? this.$route.query.encrypted : 'N'
            var url = `/api/mzapps/couponlist/${this.customer_id}?page=1&perpage=5&sort=+coupon_name&timestamp=${new Date().getTime()}&country_code=${this.$route.params.country}&encrypted=${encrypt}`;
            await this.$axios.$get(url).then(response => {
                this.activeCount = response.summary.totalsize;
                
            }).catch(error => {
                this.activeCount = 0
            })
        },

         //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< history Coupon list api function >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
       getHistoryCoupons: function () {
        console.log("getHistoryCoupons");
        var encrypt = this.$route.query.encrypted ? this.$route.query.encrypted : 'N'
            var url = `/api/mzapps/history/couponlist/${this.customer_id}?page=1&perpage=5&sort=+coupon_name&list_type=T&timestamp=${new Date().getTime()}&country_code=${this.$route.params.country}&encrypted=${encrypt}`;
            this.$axios.$get(url).then(response => {
                this.historycount = response.summary.totalsize;  
                
            }).catch(error => {
                console.log("error",error);
                this.historycount = 0
            })
        },

        getexpiryCoupons: function () {
        console.log("getHistoryCoupons");
        var encrypt = this.$route.query.encrypted ? this.$route.query.encrypted : 'N'
            var url = `/api/mzapps/history/couponlist/${this.customer_id}?page=1&perpage=5&sort=+coupon_name&list_type=E&timestamp=${new Date().getTime()}&country_code=${this.$route.params.country}&encrypted=${encrypt}`;
            this.$axios.$get(url).then(response => {
                this.expirycount = response.summary.totalsize;  

            }).catch(error => {
                console.log("error",error);
                this.expirycount = 0
            })
        },

        //  <=============================== Get Customer Setting API function for wallet ==============================>>>>

        getCustomerSetting: function () {
            var url = `/api/mzapps/customersetting?timestamp=${new Date().getTime()}&country_code=${this.$route.params.country}`;
            this.$axios.$get(url).then(response => {
                this.customerSettingDetails = response.customer_setting;
                console.log("customerSettingDetails", this.customerSettingDetails);
            }).catch(error => {

            })
        },
        //<---------------------------- customer details api function ----------------------------->

        async GetCustomer() {
            var customerId = this.customer_id;
             console.log('MobileMenu -customer_id',customerId);
            var encrypt = this.$route.query.encrypted ? this.$route.query.encrypted : 'N'
            await this.$axios.$get(`/api/mzapps/customer/${customerId}?timestamp=${new Date().getTime()}&country_code=${this.$route.params.country}&encrypted=${encrypt}`).then(response => {
                console.log('customer respponse', response);
                this.customerDetails = response;
                console.log("customerDetails", this.customerDetails)
                var customerGroupId = this.customerDetails.customer_group_id

                this.getCustomerMembership(customerGroupId)
            }).catch(error => {
                // console.log("customer Error ", error);
            })

        },

        //<------------------------------ customer membership api function --------------------->
        getCustomerMembership: function (customerGroupId) {
            var url = `/api/mzapps/membership/${customerGroupId}?timestamp=${new Date().getTime()}&country_code=${this.$route.params.country}`;
            console.log("membership url", url);
            this.$axios.$get(url).then(response => {
                this.customerMembershipDetails =  this.$store.state.sessionStorage.customerMembershipDetails;
                this.customer_group_image = this.customerMembershipDetails.group_image ? this.customerMembershipDetails.group_image : this.merchantDetails.merchant_logourl
                this.walletQR = this.customerMembershipDetails.group_image;
                console.log("customerMembershipDetails mobile menu", this.customerMembershipDetails);
            }).catch(error => {
                this.customer_group_image =  this.merchantDetails.merchant_logourl;
                console.log("getCustomerMembership error", error)
            })
        },

        hideMobileMenu: function () {
            document.querySelector('body').classList.remove('mmenu-active');
        },
        submitSearchForm: function () {
            this.hideMobileMenu();
            this.$router.push({
                path: '/shop/sidebar/3cols',
                query: {
                    searchTerm: this.searchTerm
                }
            });
        },

        ViewWalletQR() {
            console.log();
            this.walletQR = this.customerDetails.encrypt_customer_id_url;
            this.walletQrShow = true;

        },
        CloseWalletQR() {
            this.walletQrShow = false;
        },

        updateCustomer() {
            console.log('this.$router.query', this.$route.query);
            var encrypted = this.$route.query.encrypted
            var country = this.$route.params.country
            if (encrypted === 'y') {
                var url = `/${country}/updatecustomer?encrypted=y`
            } else {
                var url = `/${country}/updatecustomer`
            }

            console.log("url", url);
            window.location.replace(url)
        },

        // <================================ Merchant Settings Api function ====================================>

        merchantSettings: function () {
            var url = `/api/mzapps/settings?country_code=${this.$route.params.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                console.log("merchantSettings ", response)
                this.MerchantSettings = response;
                if (this.MerchantSettings) {
                    if (this.MerchantSettings.selected_language) {
                        var selected_language = this.MerchantSettings.selected_language;
                        var selectLanguage = [];
                        if (selected_language.kr) {
                            var language_object_kr = {};
                        }
                        if (selected_language.en) {
                            var language_object_en = {};
                        }
                        if (selected_language.zh_Hant) {
                            var language_object_zh_Hant = {};
                        }
                        if (selected_language.zh_Hans) {
                            var language_object_zh_Hans = {};
                        }
                        if (selected_language.id) {
                            var language_object_id = {};
                        }
                        if (selected_language.th) {
                            var language_object_th = {};
                        }
                        if (selected_language.ja) {
                            var language_object_ja = {};
                        }
                        if (selected_language.kr) {
                            language_object_kr.name = "Korean (한국어)";
                            language_object_kr.code = "kr";
                            language_object_kr.code_align = "F";
                            selectLanguage.push(language_object_kr);
                        }

                        if (selected_language.en) {
                            language_object_en.name = "English";
                            language_object_en.code = "en";
                            language_object_en.code_align = "A";
                            selectLanguage.push(language_object_en);
                        }

                        if (selected_language.zh_Hant) {
                            language_object_zh_Hant.name = "Chinese Traditional (繁體中文)";
                            language_object_zh_Hant.code = "zh_Hant";
                            language_object_zh_Hant.code_align = "C";
                            selectLanguage.push(language_object_zh_Hant);
                        }

                        if (selected_language.zh_Hans) {
                            language_object_zh_Hans.name = "Chinese Simplified (簡體中文)";
                            language_object_zh_Hans.code = "zh_Hans";
                            language_object_zh_Hans.code_align = "D";
                            selectLanguage.push(language_object_zh_Hans);
                        }

                        if (selected_language.id) {
                            language_object_id.name = "Bahasa Indonesia";
                            language_object_id.code = "id";
                            language_object_id.code_align = "B";
                            selectLanguage.push(language_object_id);
                        }

                        if (selected_language.th) {
                            language_object_th.name = "Thai (ภาษาไทย)";
                            language_object_th.code = "th";
                            language_object_th.code_align = "G";
                            selectLanguage.push(language_object_th);
                        }

                        if (selected_language.ja) {
                            language_object_ja.name = "Japanese (日本語)";
                            language_object_ja.code = "ja";
                            language_object_ja.code_align = "E";
                            selectLanguage.push(language_object_ja);
                        }

                        this.selectedLang = selectLanguage;
                        this.selectedLang.sort((a, b) => a.code_align.localeCompare(b.code_align));
                    }
                }

                console.log('this.selectedLang', this.selectedLang);
            }).catch(error => {

            })
        },

        // <<<<<<<<<<<<<<<<<<<<<<< language change function >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        lang_change: function (lang_code) {
            this.$store.commit('sessionStorage/assignSelectLang', lang_code);
            window.location.reload()
            // MultiLang(lang_code)
        },

        // <================================ Merchant Detail Api function ====================================>

        getMerchantDetails: function () {
            var url = `/api/mzapps/merchant/${this.$store.state.sessionStorage.merchant_id}?country_code=${this.$route.params.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                this.merchantDetails = response.merchant;
                console.log('FoootermerchantDetails', this.merchantDetails);
                // document.title = `Official E-Voucher Store | ${this.merchantDetails.merchant_name}`
            }).catch(error => {

            })
        },
    }
};
</script>

<style>
.cust-data {
    padding: 2em !important;
}

.pre-des-mob {
    word-wrap: break-word !important;
    color: #fff !important;
}

.summary-title-mob {
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 0;
    padding-bottom: 0 !important;
    border-bottom: none !important;
    margin-bottom: 0.5rem !important;
    color: #fff !important;
}

#mob-btn {
    border-color: #fff !important;
    color: #fff !important;
}

#mob-btn:hover {
    border-color: #fff !important;
    color: #fff !important;
    background-color: #333333 !important;
}

.qr-img {
    padding: 2em !important;
}
.item-count{
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1rem;
    top: 50%;
    z-index: 10;
    width: 3rem;
    height: 3rem;
    font-size: 1.2rem;
    color: #fff;
    margin-top: -1.5rem;
    
   
   
}
.mobile-menu span:not(.mmenu-btn):not(.tip) {
    position: absolute !important;
}
</style>
